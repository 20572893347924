#dropzone {
    display: flex;
    width: 100%;
    height: 100%;
    min-height: 5rem;
    border: 2px dashed silver;
    border-radius: 5px;
}

#dropzone span {
    font-size: 1rem;
    margin: auto;
}

input[type="file"] {
    display: none;
}